import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./NavigationMenu.css";

const NavigationMenu = () => {
  const [open, setOpen] = useState(false);

  // Function to toggle the menu
  const toggleMenu = () => {
    setOpen(!open);
  };

  // Function to close the menu
  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <nav className={`menu-container ${open ? "open" : ""}`}>
      <div className="menu-icon" onClick={toggleMenu}>
        ☰
      </div>
      <div className={`navigation-menu ${open ? "open" : ""}`}>
        <Link to="/" className="nav-link" onClick={closeMenu}>
          🏠 SpeedOmeter
        </Link>
        <Link to="/leaderboard" className="nav-link" onClick={closeMenu}>
          🏆 Leaderboard
        </Link>
        <Link to="/about" className="nav-link" onClick={closeMenu}>
          ℹ️ About
        </Link>
        <Link to="/settings" className="nav-link" onClick={closeMenu}>
          ⚙️ Settings
        </Link>
      </div>
    </nav>
  );
};

export default NavigationMenu;