import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; 

import Loading from "./Loading";
import NavigationMenu from "./NavigationMenu"; // Import the custom navigation menu component
import "./App.css"; // Import a CSS file for global styles

const About = lazy(() => import("./About"));
const SpeedOmeter = lazy(() => import("./SpeedOmeter"));
const Footer = lazy(() => import("./Footer"));
const Leaderboard = lazy(() => import("./Leaderboard"));
const Settings = lazy(() => import("./Settings"));

const App: React.FC = () => (
  <Router>
    <Suspense fallback={<Loading />}>
      {/* Include the custom navigation menu */}
      <NavigationMenu />

      <Routes>
        <Route path='/about' element={<About/>} />
        <Route path='/leaderboard' element={<Leaderboard/>} />
        <Route path='/settings' element={<Settings/>} />
        <Route path='/' element={<SpeedOmeter/>} />
      </Routes>
    </Suspense>
    <Footer />
  </Router>
);

export default App;